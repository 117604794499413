import {
  Alert,
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomerNew() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [saveDone, setSaveDone] = React.useState(false);
  const [newCustKey, setNewCustKey] = React.useState("-1");

  const handleSaveClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call customer/create");
    setSaveDone(false);
    setNewCustKey("-1");

    try {
      // const req = {};
      // req.body = {
      const req = {
        title: title,
        name: name,
      };
      await api
        .post("customer/create", req, { withCredentials: true })
        .then((response) => {
          console.log("customer/create response", response.data);
          setNewCustKey(response.data.customerKey);
          setSaveDone(true);
        });
    } catch (error) {
      console.log("customer/create error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <p>New Customer</p>
      {!saveDone ? (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="ข้อมูลลูกค้า" {...a11yProps(0)} />
            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormGroup>
            <TextField
              id="standard-basic"
              label="ชื่อ"
              variant="filled"
              required
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
            <TextField
              id="standard-basic2"
              label="ชื่อเต็ม"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormGroup>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel> */}
      </Box>
      ) : (
        <Alert severity="success">Save success.</Alert>
      ) }
      {/* <Button variant="contained" >
        <SaveIcon /> บันทึก
      </Button> */}
      <Stack direction="row" spacing={2} sx={{marginTop:5}}>
        {saveDone ? (
          <>
            <Button variant="outlined">
              <CheckIcon /> เปิดดู
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log("newCustKey", newCustKey);
                navigate("/customerEdit", {
                  state: { customerKey: newCustKey },
                });
              }}
            >
              <EditIcon /> แก้ไข
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log("Goto customerNew");
                navigate("/customerNew");
              }}
            >
              <AddIcon /> สร้างใหม่
            </Button>
          </>
        ) : (
          <LoadingButton
            onClick={handleSaveClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>บันทึก</span>
          </LoadingButton>
        )}
      </Stack>
    </>
  );
}
