import React from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LoadingButton } from "@mui/lab";
import useAlert from "../context/useAlert";
import api from "../services/api";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function LineAccountEdit({
  callbackResult,
  lineAccount
}) {
  //const [lineWebhookEdit, setLineWebhookEdit] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [remark, setRemark] = React.useState("");
  const [active, setActive] = React.useState(true);
  const [accessToken, setAccessToken] = React.useState("");
  const [editLineAccount, setEditLineAccount] = React.useState([]);
  const [targetLimitSendingMessagesThisMonth, setTargetLimitSendingMessagesThisMonth] = React.useState();
  const [targetLimitSendingMessagesThisMonthType, setTargetLimitSendingMessagesThisMonthType] = React.useState();
  const [numberOfMessagesSentThisMonth, setNumberOfMessagesSentThisMonth] = React.useState();
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()).set('hour', 0).set('minute', 0));
  const [numberOfSentReplyMessages, setNumberOfSentReplyMessages] = React.useState(null);
  const [numberOfSentPushMessages, setNumberOfSentPushMessages] = React.useState();
  const [numberOfSentMulticastMessages, setNumberOfSentMulticastMessages] = React.useState();
  const [numberOfSentBroadcastMessages, setNumberOfSentBroadcastMessages] = React.useState();
  const [lastestSentCmd, setLastestSentCmd] = React.useState();
  
  const { setAlert } = useAlert();

  React.useEffect(() => {
    console.log("useEffect LineAccountEdit ", lineAccount);

    // setRemark(lineGroup.remark);
    setActive(lineAccount.active);
    setAccessToken(lineAccount.accessToken);
    // setBasicId(lineGroup.basicId);
    setEditLineAccount(lineAccount);
    // setLineWebhookEdit(lineWebhook);
  }, []);

  function renderResponse(param) {
    return (
      <>
        {param ? <><Chip label={"Success: " + param.success} /> <Chip label={"Status: " + param.status} /></> : ''}
      </>
    )
  }
  const handleGetTargetLimitSendingMessagesThisMonth = async (param) => {

    setLoading(true);
    console.log("Call getStat getTargetLimitSendingMessagesThisMonth");
    setTargetLimitSendingMessagesThisMonth(null);
    setTargetLimitSendingMessagesThisMonthType(null);

    try {
      // const req = {};
      // req.body = {
      const req = {
        key: accessToken,
        name: 'getTargetLimitSendingMessagesThisMonth'
      };
      await api
        .post("lineAccount/getStat", req, { withCredentials: true })
        .then((response) => {
          //console.log("lineAccount/update response", response.data);
          if (response.data.value) {
            setTargetLimitSendingMessagesThisMonth("Value: " + response.data.value);
          }          
          setTargetLimitSendingMessagesThisMonthType("Type: " + response.data.type);
        });
    } catch (error) {
      setAlert(
        "Call failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGetNumberOfMessagesSentThisMonth = async (param) => {

    setLoading(true);
    console.log("Call getStat getNumberOfMessagesSentThisMonth");
    setNumberOfMessagesSentThisMonth(null);

    try {      
      const req = {
        key: accessToken,
        name: 'getNumberOfMessagesSentThisMonth'
      };
      await api
        .post("lineAccount/getStat", req, { withCredentials: true })
        .then((response) => {          
          setNumberOfMessagesSentThisMonth("TotalUsage: " + response.data.totalUsage);
        });
    } catch (error) {
      setAlert(
        "Call failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGetNumberOfMessages = async (param) => {

    setLoading(true);
    console.log("Call getStat getNumberOfMessages", param);
    setNumberOfSentReplyMessages(null);
    setLastestSentCmd(param);

    try {      
      const req = {
        key: accessToken,
        name: param,
        fromDate: fromDate.format('YYYYMMDD')
      };
      await api
        .post("lineAccount/getStat", req, { withCredentials: true })
        .then((response) => {          
          setNumberOfSentReplyMessages(response.data);
        });
    } catch (error) {
      setAlert(
        "Call failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGetBotInfo = async (param) => {
    setLoading(true);
    console.log("Call lineAccount/getBotInfo");

    try {
      const req = {        
        key: lineAccount.basicId
      };
      await api
        .post("lineAccount/getBotInfo", req, { withCredentials: true })
        .then((response) => {
          console.log("lineAccount/getBotInfo response", response.data);
        });
    } catch (error) {
      setAlert(
        "Create failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSaveClick = async (param) => {
    setLoading(true);
    console.log("Call lineAccount/update");

    try {
      // const req = {};
      // req.body = {
      const req = {
        key: lineAccount.basicId,
        accessToken: lineAccount.basicId,
        active: active
      };
      await api
        .post("lineAccount/update", req, { withCredentials: true })
        .then((response) => {
          console.log("lineAccount/update response", response.data);
          callbackResult("confirm", "ok");
          setAlert("Update lineAccount success.", "success");
        });
    } catch (error) {
      setAlert(
        "Create failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Typography variant="subtitle1">
              Edit Line Account: {lineAccount.basicId} 
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {lineAccount.publicUrl ? <Avatar src={lineAccount.publicUrl} /> : ""}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Access Token"
              value={accessToken}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(event) => {
                    setActive(event.target.checked);
                  }}
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
              <LoadingButton
                onClick={handleSaveClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>บันทึก</span>
              </LoadingButton>
              <LoadingButton
                onClick={handleGetBotInfo}
                loading={loading}
                loadingPosition="start"
                startIcon={<RefreshIcon />}
                variant="contained"
              >
                <span>Bot Info</span>
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={2}>
        <Grid container spacing={2} justifyContent="center" alignItems="center" rowSpacing={1}>
          <Grid item xs={12}>
          <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
            <Typography variant="subtitle1">
              Stats for 
            </Typography><Typography key="3" color="text.primary">{lineAccount.basicId}</Typography>{lineAccount.publicUrl ? <Avatar src={lineAccount.publicUrl} /> : ""}</Breadcrumbs>
          </Grid>
          <Grid item xs={3}>
            {targetLimitSendingMessagesThisMonth ? <><Chip label={targetLimitSendingMessagesThisMonth} /> <Chip label={targetLimitSendingMessagesThisMonthType} /></>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" size="small" loading={loading} onClick={handleGetTargetLimitSendingMessagesThisMonth}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get the target limit for sending messages this month</p>
          </Grid>
          <Grid item xs={3}>
            {numberOfMessagesSentThisMonth ? <><Chip label={numberOfMessagesSentThisMonth} /></>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" loading={loading} size="small" onClick={handleGetNumberOfMessagesSentThisMonth}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get number of messages sent this month</p>
          </Grid>
          <Grid item xs={4}>            
            <DatePicker label="วันที่" defaultValue={fromDate} value={fromDate} onChange={(newValue) => {
              console.log('fromDate', newValue);
              setFromDate(newValue);
            }} />            
          </Grid>
          <Grid item xs={8}>
            { lastestSentCmd ? <Chip color="primary" label={lastestSentCmd} /> : "" }
          </Grid>
          <Grid item xs={3}>
            {numberOfSentReplyMessages ? <>{renderResponse(numberOfSentReplyMessages)}</>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" loading={loading} size="small" onClick={() => {
              handleGetNumberOfMessages('getNumberOfSentReplyMessages');
            }}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get number of sent reply messages</p>
          </Grid>
          <Grid item xs={3}>
            {numberOfSentPushMessages ? <>{renderResponse(numberOfSentPushMessages)}</>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" loading={loading} size="small" onClick={() => {
              handleGetNumberOfMessages('getNumberOfSentPushMessages');
            }}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get number of sent push messages</p>
          </Grid>
          <Grid item xs={3}>
            {numberOfSentMulticastMessages ? <>{renderResponse(numberOfSentMulticastMessages)}</>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" loading={loading} size="small" onClick={() => {
              handleGetNumberOfMessages('getNumberOfSentMulticastMessages');
            }}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get number of sent multicast messages</p>
          </Grid>
          <Grid item xs={3}>
            {numberOfSentBroadcastMessages ? <>{renderResponse(numberOfSentBroadcastMessages)}</>: <></>}
          </Grid>
          <Grid item xs={1}>
            <LoadingButton variant="outlined" loading={loading} size="small" onClick={() => {
              handleGetNumberOfMessages('getNumberOfSentBroadcastMessages');
            }}>Load</LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <p>Get number of sent broadcast messages</p>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
