import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import api from "../services/api";
import useAlert from "../context/useAlert";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";
import LineWebhookView from "./LineWebhookView";
import dayjs from "dayjs";

function getDateText(params) {
  return moment(new Date(params.row.recordDate)).format('D MMM yyyy HH:MM');
}
function getJson(params) {
  return JSON.stringify(params.row.webhookEvent);
}

export default function LineWebhook() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()).set('hour', 0).set('minute', 0));
  const [toDate, setToDate] = React.useState(dayjs(new Date()).set('hour',23));
  const [rows, setRows] = React.useState([]);
  const [lineWebhookEdit, setLineWebhookEdit] = React.useState();

  React.useEffect(() => {
    console.log("useEffect LineWebhook", fromDate);
  }, []);

  function getKey(params) {
    return (<><IconButton aria-label="Edit" onClick={(e) => {    
      setLineWebhookEdit(params.row);
    }}>
    <EditIcon />
  </IconButton></>);
  }

  const columns = [
    { headerName: "", width: 40, renderCell: getKey },
    { field: "lwhKey", headerName: "Key", width: 120 },
    { field: "recordDate", headerName: "Date", width: 160, valueGetter: getDateText },
    { field: "webhookEvent", headerName: "JSON", width: 400, valueGetter: getJson },
    { field: "header", headerName: "Header", width: 200 },
    
  ];

  const { setAlert } = useAlert();

  const handleSearchClick = async (param) => {
    
    setLoading(true);
    console.log("Call agentTask/search", fromDate, toDate);
    console.log("fromDate", fromDate.format('YYYY-MM-DD HH:mm'));
    


    try {
      
      const req = {
        fromDate: fromDate.format('YYYY-MM-DD HH:mm'), toDate: toDate.format('YYYY-MM-DD HH:mm')
      };
      await api
        .post("lineWebhook/search", req, { withCredentials: true })
        .then((response) => {
          setRows(response.data);          
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');
      // console.log("customer/search error ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <><LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant="subtitle1" >Line Webhook</Typography>
        
      <Stack spacing={2} direction="row">
        <DateTimePicker label="ตั้งแต่" defaultValue={fromDate} value={fromDate} onChange={(newValue) => {
          console.log('fromDate', newValue);
          const d = new Date(newValue);
          setFromDate(newValue);
          // setFromDate(""+d.getTime());        
        }} />
        <DateTimePicker label="ถึง" defaultValue={toDate} value={toDate} onChange={(newValue) => {
          console.log('toDate', newValue);
          const d = new Date(newValue);
          setToDate(newValue); 
          // setToDate(""+d.getTime());        
        }} />
          <LoadingButton
            onClick={handleSearchClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            variant="contained"
          >
            <span>ค้นหา</span>
          </LoadingButton>
      </Stack>

      <Box
        sx={{
          width: "100%",
          height: 400,
          marginTop: 2,
          
        }}
      >
        <DataGrid rows={rows} columns={columns} getRowId={(row) => row.lwhKey} />
      </Box>

      {
        lineWebhookEdit ? (
              <>
                <LineWebhookView lineWebhook={lineWebhookEdit} />
              </>
            ) : ""
          }
      </LocalizationProvider>
    </>
  );
}
