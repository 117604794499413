import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import api from "../services/api";
import useAlert from "../context/useAlert";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { AlertProvider } from "../context/AlertContext";

// const rows = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
// ];

function getDateText(params) {
  return moment(new Date(params.row.createDate)).format("D MMM yyyy HH:MM");
}

export default function Configuration() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [appConfigs, setAppConfigs] = React.useState([]);

  const { setAlert } = useAlert();

  const handleSearchClick = async (param) => {
    setLoading(true);
    console.log("Call appConfig/list");

    try {
      await api
        .get("appConfig/list", { withCredentials: true })
        .then((response) => {
          setAppConfigs(response.data);
        });
    } catch (error) {
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSaveClick = async (param) => {
    setLoading(true);
    console.log("Call appConfig/update");

    try {
      const req = {
        name: "Check",
        appConfigForms: appConfigs,
      };
      await api
        .post("appConfig/update", req, { withCredentials: true })
        .then((response) => {
          console.log("appConfig/update response", response.data);

          setAlert("Update configuraion success.", "success");
        });
    } catch (error) {
      setAlert(
        "Update failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <><AlertProvider>
      <Typography variant="subtitle1">Application Configuration</Typography>
      <Stack spacing={2} direction="row">
        <TextField
          id="filled-basic"
          label="ชื่อ"
          variant="outlined"
          size="small"
          value={txt}
          onChange={(event) => {
            setTxt(event.target.value);
          }}
        />
        <LoadingButton
          onClick={handleSearchClick}
          loading={loading}
          loadingPosition="start"
          startIcon={<SearchIcon />}
          variant="contained"
        >
          <span>ค้นหา</span>
        </LoadingButton>
      </Stack>

      <Box
        sx={{
          width: "100%",
          marginTop: 5,
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="baseline"
        >
          {appConfigs.map((item, key) => (
            <>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  label="Key"
                  variant="filled"
                  value={item.appConfigKey}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  label="Name"
                  variant="filled"
                  value={item.name}
                  onChange={(event) => {
                    //setName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Value"
                  variant="filled"
                  value={item.value}
                  onChange={(event) => {
                    //setName(event.target.value);
                    //console.log('Modified appConfigKey', event.target.value);

                    var i;
                    for (i = 0; i < appConfigs.length; i++) {
                      //console.log(' appConfigKey', appConfigs[i].appConfigKey);
                      if (item.appConfigKey === appConfigs[i].appConfigKey) {
                        //appConfigs[i].value = event.target.value;
                        break;
                      }
                    }

                    var a2 = appConfigs.map(function (currentValue, index) {
                      if (item.appConfigKey === currentValue.appConfigKey) {
                        currentValue.value = event.target.value;
                        //console.log(' Update value for appConfigKey', appConfigs[i].appConfigKey);
                      }

                      return currentValue;
                    });

                    //console.log(' a2', a2);
                    setAppConfigs(a2);
                  }}
                />
              </Grid>
            </>
          ))}
          <Grid item xs={12}></Grid>
        </Grid>

        {appConfigs.length > 0 ? (
          <LoadingButton
            onClick={handleSaveClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>บันทึก</span>
          </LoadingButton>
        ) : (
          ""
        )}
      </Box></AlertProvider>
    </>
  );
}
