import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import useAlert from "../context/useAlert";
import AlertPopup from "./AlertPopup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AgentTaskNew() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { setAlert } = useAlert();
  
  const [active, setActive] = React.useState(true);
  const [name, setName] = React.useState("");
  const [filepath, setFilepath] = React.useState("");
  const [filename, setFilename] = React.useState("");
  const [cron, setCron] = React.useState("");
  const [type, setType] = React.useState("FILE");
  const [day, setDay] = React.useState(0);
  const [textSuccess, setTextSuccess] = React.useState("");
  const [textSuccessEnd, setTextSuccessEnd] = React.useState("");
  const [textItems, setTextItems] = React.useState("");
  
  const [textLinePrefix, setTextLinePrefix] = React.useState("");
  const [textLineSuffixRemovable, setTextLineSuffixRemovable] = React.useState("");
  const [textDateFormat, setTextDateFormat] = React.useState("");
  const [textDateTimeFormat, setTextDateTimeFormat] = React.useState("");
  const [notiMsgSuccess, setNotiMsgSuccess] = React.useState("");
  const [notiMsgError, setNotiMsgError] = React.useState("");

  const [saveDone, setSaveDone] = React.useState(false);
  const [newCustKey, setNewCustKey] = React.useState("-1");

  const handleSaveClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call agentTask/create");
    setSaveDone(false);
    setNewCustKey("-1");

    try {
      // const req = {};
      // req.body = {
      const req = {
        name: name,
        type: type,
        cron: cron,
        active: active,
        filepath: filepath,
        filename: filename,
        day: day,
        textSuccess: textSuccess,
        textSuccessEnd: textSuccessEnd,
        textItems: textItems,
        textLinePrefix: textLinePrefix,
        textLineSuffixRemovable: textLineSuffixRemovable,
        textDateFormat: textDateFormat,
        textDateTimeFormat: textDateTimeFormat,
        notiMsgSuccess: notiMsgSuccess,
        notiMsgError: notiMsgError
      };
      await api
        .post("agentTask/create", req, { withCredentials: true })
        .then((response) => {
          console.log("agentTask/create response", response.data);
          //setNewCustKey(response.data.customerKey);
          setSaveDone(true);

          setAlert(
              "Create Agent Task success.", "success"
            );
        });
    } catch (error) {
      setAlert(
        "Search node error code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <p>New AgentTask</p>
      <AlertPopup/>
      {!saveDone ? (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="ข้อมูล" {...a11yProps(0)} />
            <Tab label="เงื่อนไขค้นหา" {...a11yProps(1)} />
            <Tab label="แจ้งเตือน" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth required
              id="name"
              label="ชื่อ"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={active} onChange={(event) => {setActive(event.target.checked)}} />} label="Active" />
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth required
              id="cron"
              label="Cron"
              variant="filled"
              value={cron}
              onChange={(event) => {
                setCron(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth required
              id="day"
              label="ปรับวัน"
              variant="filled"
              value={day}
              onChange={(event) => {
                setDay(event.target.value);
              }}
              helperText={"ตย. -1 : เมื่อวาน"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth 
              id="filepath"
              label="File path"
              variant="filled"
              value={filepath}
              onChange={(event) => {
                setFilepath(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth 
              id="filename"
              label="File name"
              variant="filled"
              value={filename}
              onChange={(event) => {
                setFilename(event.target.value);
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
           <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Type"
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              <MenuItem value={"FILE"}>Log File</MenuItem>
            </Select>
          </Grid>

        </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="textLinePrefix"
                label="Line Prefix"
                variant="filled"
                value={textLinePrefix}
                onChange={(event) => {
                  setTextLinePrefix(event.target.value);
                }}
                helperText={"ตย. [INFO]"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="textLineSuffixRemovable"
                label="Removable suffix"
                variant="filled"
                value={textLineSuffixRemovable}
                onChange={(event) => {
                  setTextLineSuffixRemovable(event.target.value);
                }}
                helperText={"ตย. [tcz.scl.service.FeederService.submitSyncStudent.245]"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="textDateFormat"
                label="Date Pattern"
                variant="filled"
                value={textDateFormat}
                onChange={(event) => {
                  setTextDateFormat(event.target.value);
                }}
                helperText={"ตย. YYYY-MM-DD"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="textDateTimeFormat"
                label="Date Time Pattern"
                variant="filled"
                value={textDateTimeFormat}
                onChange={(event) => {
                  setTextDateTimeFormat(event.target.value);
                }}
                helperText={"ตย. YYYY-MM-DD HH:mm:dd"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth required
                id="textSuccess"
                label="คำที่ค้นหา (เริ่มต้น)"
                variant="filled"
                value={textSuccess}
                onChange={(event) => {
                  setTextSuccess(event.target.value);
                }}
                helperText={"ตย. Job update done"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth
                id="textSuccessEnd"
                label="คำที่ค้นหา (ลงท้าย)"
                variant="filled"
                value={textSuccessEnd}
                onChange={(event) => {
                  setTextSuccessEnd(event.target.value);
                }}
                helperText={"ตย. JOB ENDED runJob1"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth
                id="textItems"
                label="รายละเอียดที่ค้นหา (Optional)"
                variant="filled"
                value={textItems}
                onChange={(event) => {
                  setTextItems(event.target.value);
                }}
                helperText={"ตย. AA|BB"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="notiMsgSuccess"
                label="ข้อความแจ้งเตือนเมื่อพบข้อความ"
                variant="filled"
                value={notiMsgSuccess}
                onChange={(event) => {
                  setNotiMsgSuccess(event.target.value);
                }}
                helperText={"ตย. งานอัพโหลดสำเร็จ"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth 
                id="notiMsgError"
                label="ข้อความแจ้งเตือนเมื่อไม่พบข้อความ"
                variant="filled"
                value={notiMsgError}
                onChange={(event) => {
                  setNotiMsgError(event.target.value);
                }}
                helperText={"ตย. งานอัพโหลดล้มเหลว"}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
      ) : "" 
      }
      {/* <Button variant="contained" >
        <SaveIcon /> บันทึก
      </Button> */}
      <Stack direction="row" spacing={2} sx={{marginTop:5}}>
        {saveDone ? (
          <>
            <Button variant="outlined">
              <CheckIcon /> เปิดดู
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log("newCustKey", newCustKey);
                navigate("/customerEdit", {
                  state: { customerKey: newCustKey },
                });
              }}
            >
              <EditIcon /> แก้ไข
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log("Goto agentTaskNew");
                navigate("/agentTaskNew");
              }}
            >
              <AddIcon /> สร้างใหม่
            </Button>
          </>
        ) : (
          <LoadingButton
            onClick={handleSaveClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>บันทึก</span>
          </LoadingButton>
        )}
      </Stack>
    </>
  );
}
