import React from "react";
import { Button, ButtonGroup, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import UndoIcon from "@mui/icons-material/Undo";
import useAlert from "../context/useAlert";
import { JsonViewer, createDataType } from '@textea/json-viewer';

export default function LineWebhookView({ lineWebhook }) {
  
  //const [lineWebhookEdit, setLineWebhookEdit] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { setAlert } = useAlert();

  React.useEffect(() => {
    console.log("useEffect LineWebhookView lineWebhook", lineWebhook);
    
    // setLineWebhookEdit(lineWebhook);
  }, []);

  const json = { test:"Hello" }

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={5}>
        <Typography variant="subtitle1">Key {lineWebhook.lwhKey}</Typography>
        <JsonViewer value={lineWebhook.webhookEvent}/>
        <TextField multiline rows={12} value={lineWebhook.header} fullWidth/>
      </Paper>
    </>
  );
}
