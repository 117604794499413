import axios from 'axios';

// const BASE_UI_URL = 'http://localhost:3000/';
const BASE_UI_URL = 'https://xnoti.tukzolo.com';
// const BASE_URL = 'http://localhost:8081/';
const BASE_URL = 'https://serversmtomcat.tukzolo.com/xnoti-ctrl/';
//const BASE_URL = 'https://kkcmsreact.tukzolo.com/api/'; 


const axiosInstance = axios.create({
	baseURL: BASE_URL,
	// headers: {
	// 	'X-Authorization': 'Anonymous'
	// }
});


axiosInstance.interceptors.response.use(function (response) {
	// Do something with response data
	console.info("interceptors response.status", response.status);
	return response;
  }, function (error) {
	// Do something with response error
	console.error("interceptors error", error.code);

	if (error.code === 'ERR_NETWORK') {
		alert('Failed to contact remote server');
		localStorage.removeItem('token');
		document.location = BASE_UI_URL;
	}

	console.error("interceptors error.response.status", error.response.status);
	if (error.response.status === 401) {
		console.info("removeItem token");
		localStorage.removeItem('token');
		document.location = BASE_UI_URL;
	}

	return Promise.reject(error);
  });

  export default axiosInstance;