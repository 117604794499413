import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import {
  BrowserRouter,
  Link,
  Outlet,
  Route,
  Routes,
  Switch,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Preferences from "./components/Preferences";
import Layout from "./components/Layout";
import Home from "./components/Home";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Badge,
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Login from "./components/Login";
import api from "./services/api";
import Customer from "./components/Customer";
import CustomerNew from "./components/CustomerNew";
import CustomerEdit from "./components/CustomerEdit";
import { AlertProvider } from "./context/AlertContext";
import CustomerView from "./components/CustomerView";
import AgentTask from "./components/AgentTask";
import AgentTaskNew from "./components/AgentTaskNew";
import AgentTaskEdit from "./components/AgentTaskEdit";
import Configuration from "./components/Configuration";
import LineWebhook from "./components/LineWebhook";
import LineGroup from "./components/LineGroup";
import LineAccount from "./components/LineAccount";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

async function checkLoginToken() {
  
  try {
    console.log("Call date/now");

    const res = await api.get("date/now", {
      withCredentials: true,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      console.log("date/now response", response.data);
    });

  } catch (error) {
    console.log("error ", error.response.status);

    var txt = "Error " + error.response.status;
    console.log("Error txt", txt);
    if (error.response.status === 401) {
      //showError(txt);
    }
  }
}

function App() {
  const [open, setOpen] = React.useState(true);
  const [token, setToken] = React.useState();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  console.log('Loading in App()');

  const token1 = localStorage.getItem('token');
  console.log('token1', token1);

  const handleLogoutClick = async (param) => {
    console.log("Call logout");

    try {
      const req = {
        
      };
      await api
        .post("logout", req, { withCredentials: true })
        .then((response) => {
          console.log("logout response", response.data);
          setToken(null); 
          localStorage.removeItem('token');
        });
    } catch (error) {
      console.log("logout error ", error);
    } finally {
      
    }
  };

  if(!token1 ) {
  // if(checkLoginToken()) {
  // if(!token) {
    console.log('Loading Login page');
    return <Login setToken={setToken} />
  }

  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Layout />}>
    //       <Route index element={<Home />} />
    //       <Route path="/dashboard">
    //         <Dashboard />
    //       </Route>
    //       <Route path="/preferences">
    //         <Preferences />
    //       </Route>
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
    <ThemeProvider theme={mdTheme}><AlertProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              XNoti or XNote
            </Typography>
            <IconButton color="inherit"></IconButton>
            
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

          <List component="nav">
            <React.Fragment>
              <ListItem button>
                <Link to="/">Home</Link>
              </ListItem>
              <ListItem button>
                <Link to="/dashboard">Dashboard</Link>
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/customer");
                  }}>                
                  <PeopleOutlineIcon/> Customers
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/agentTask");
                  }}>                
                  <AssignmentTurnedInIcon/> Agent Tasks
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/lineWebhook");
                  }}>                                  
                  <RequestPageIcon/> Line Webhook
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/lineGroup");
                  }}>                                  
                  <PeopleIcon/> Line Group
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/lineAccount");
                  }}>                                  
                  <PeopleIcon/> Line Account
              </ListItem>
              <ListItem button onClick={() => {                    
                    navigate("/configuration");
                  }}>                
                  <SettingsIcon/> Configuration
              </ListItem>
              <ListItem button>                
                <Button size="small"
                  variant="text"
                  onClick={handleLogoutClick}
                >
                  <LogoutIcon/> Logout
                </Button>
              </ListItem>
            </React.Fragment>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          
          <Routes>
            <Route path="/" element={<Layout2 />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/preferences" element={<Preferences />} />
              <Route path="/customer" element={<Customer/>} />
              <Route path="/customerNew" element={<CustomerNew/>} />
              <Route path="/customerEdit" element={<CustomerEdit/>} />
              <Route path="/customerView" element={<CustomerView/>} />
              <Route path="/agentTask" element={<AgentTask/>} />
              <Route path="/lineWebhook" element={<LineWebhook/>} />
              <Route path="/lineGroup" element={<LineGroup/>} />
              <Route path="/lineAccount" element={<LineAccount/>} />
              <Route path="/agentTaskNew" element={<AgentTaskNew/>} />
              <Route path="/agentTaskEdit" element={<AgentTaskEdit/>} />
              <Route path="/configuration" element={<Configuration/>} />
            </Route>
          </Routes>
        </Box>
      </Box></AlertProvider>
    </ThemeProvider>
  );
}

function Layout2() {
  return (
    
      <Container maxWidth="xl" sx={{ marginTop: "80px" }}>
        <Outlet />
      </Container>    
  );
}

export default App;
