import React from "react";

import SaveIcon from "@mui/icons-material/Save";
import ViewListIcon from '@mui/icons-material/ViewList';
import RefreshIcon from "@mui/icons-material/Refresh";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { LoadingButton } from "@mui/lab";
import useAlert from "../context/useAlert";
import api from "../services/api";
import dayjs from "dayjs";
import {
  Avatar,
  Button,
  ListItemIcon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";

export default function LineAccountUser({ callbackResult, lineAccount }) {
  const [loading, setLoading] = React.useState(false);
  const { setAlert } = useAlert();
  const [userList, setUserList] = React.useState([]);

  React.useEffect(() => {
    console.log("useEffect LineAccountUser ", lineAccount);
  }, []);



  const handleReloadUser = async (param) => {
    setLoading(true);
    console.log("Call lineAccount/reloadUser");

    try {
      const req = {        
        key: lineAccount.basicId
      };
      await api
        .post("lineAccount/reloadUser", req, { withCredentials: true })
        .then((response) => {
          console.log("lineAccount/reloadUser response", response.data);
        });
    } catch (error) {
      setAlert(
        "Create failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };



  const handleListUser = async (param) => {
    setLoading(true);
    console.log("Call lineAccount/listUser");

    try {
      const req = {        
        key: lineAccount.basicId
      };
      await api
        .post("lineAccount/listUser", req, { withCredentials: true })
        .then((response) => {
          console.log("lineAccount/listUser response", response.data);
          setUserList(response.data);
        });
    } catch (error) {
      setAlert(
        "Create failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={2}>
        <p>Users</p>
        <Toolbar variant="dense">
          <LoadingButton size="small" 
            loading={loading}
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            variant="contained" color="secondary" onClick={handleListUser}
          >
            <span>List</span>
          </LoadingButton>
          <LoadingButton size="small" style={{marginLeft:5}}
            loading={loading}
            loadingPosition="start"
            startIcon={<ViewListIcon />}
            variant="contained" color="secondary" onClick={handleReloadUser}
          >
            <span>Reload</span>
          </LoadingButton>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right" />
                <TableCell>User Id</TableCell>
                <TableCell>Display Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((row) => (
                <TableRow
                  key={row.userId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Button
                      value={row.userId}
                      onClick={(e) => {
                        console.log("Clicked add customer", e.target.value);
                      }}
                    >
                      OK
                    </Button>
                    {row.pictureUrl ? <Avatar src={row.publicUrl} /> : ""}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {row.userId}
                  </TableCell>
                  <TableCell>{row.displayName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
