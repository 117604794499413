import { Avatar, Box, Button, Checkbox, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import api from "../services/api";
import useAlert from "../context/useAlert";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";
import LineWebhookView from "./LineWebhookView";
import dayjs from "dayjs";
import LineGroupEdit from "./LineGroupEdit";
import LineAccountUser from "./LineAccountUser";

function getDateText(params) {
  return moment(new Date(params.row.recordDate)).format('D MMM yyyy HH:MM');
}
function getDateText2(params) {
  return moment(new Date(params)).format('D MMM yyyy HH:MM');
}
function getChecked(params) {
  //return params.row.registered === true ? <>ok</> : <>no</>;
  return (<Checkbox checked={params.registered} defaultChecked={params.registered} label={params.registered}/>);
}

export default function LineGroup() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()).set('hour', 0).set('minute', 0));
  const [toDate, setToDate] = React.useState(dayjs(new Date()).set('hour',23));
  const [rows, setRows] = React.useState([]);
  const [lineGroupEdit, setLineGroupEdit] = React.useState();
  const [showEdit, setShowEdit] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [showUsers, setShowUsers] = React.useState(false);

  React.useEffect(() => {
    console.log("useEffect LineGroup", fromDate);
    loadCustomer();
  }, []);

  const { setAlert } = useAlert();

  const callbackResult = (param, param2) => {
    console.log("callbackResult ", param, param2);

    if (param && param === "cancel") {
     
    } else if (param === "confirm") {
      setLineGroupEdit(null);
      setShowEdit(false);
      handleSearchClick();
    }
  };

  const loadCustomer = async (param) => {
    
    setLoading(true);
    console.log("Call customer/search");

    try {
      
      const req = {
        txt: ''
      };
      await api
        .post("customer/search", req, { withCredentials: true })
        .then((response) => {
          console.log(" customer size", response.data);
          setCustomers(response.data);
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTestClick = async (param) => {
    
    setLoading(true);
    console.log("Call lineGroup/test");
    
    try {      
      const req = {
       
      };
      await api
        .post("lineGroup/test", req, { withCredentials: true })
        .then((response) => {          
                  
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');      
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClick = async (param) => {
    
    setLoading(true);
    console.log("Call lineGroup/search", fromDate, toDate);
    console.log("txt", txt);
    setShowEdit(false);
    setLineGroupEdit(null);
    
    try {      
      const req = {
        txt: txt
      };
      await api
        .post("lineGroup/search", req, { withCredentials: true })
        .then((response) => {          
          setRows(response.data);          
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');      
    } finally {
      setLoading(false);
    }
  };

  return (
    <><LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant="subtitle1" >Line Group</Typography>
      
      <Stack spacing={2} direction="row">
        <TextField
          id="filled-basic"
          label="ชื่อ"
          variant="outlined"
          size="small" value={txt} 
              onChange={(event) => {
                setTxt(event.target.value);
              }}
        />
          <LoadingButton
            onClick={handleSearchClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            variant="contained"
          >
            <span>ค้นหา</span>
          </LoadingButton>
          <LoadingButton
            onClick={handleTestClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            variant="contained"
          >
            <span>Test 1</span>
          </LoadingButton>
      </Stack>


      <TableContainer component={Paper} style={{marginTop:10}}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width={100}></TableCell>
            <TableCell width={100}>Line Account</TableCell>
            <TableCell width={200}>Group Id</TableCell>
            <TableCell width={80}></TableCell>
            <TableCell width={150}>Group Name</TableCell>
            <TableCell width={120} align="right">Create Date</TableCell>
            <TableCell width={80} >Registered</TableCell>
            <TableCell width={200} >Remark</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.groupId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <IconButton aria-label="Edit" onClick={(e) => { 
                  // setShowEdit(false);
                  // setLineGroupEdit({});
                  setShowEdit(true);
                  setLineGroupEdit(row);
                }}>
                  <EditIcon />
                </IconButton> 
                <IconButton aria-label="View Users" onClick={(e) => { 
                  setLineGroupEdit(row);
                  setShowEdit(false);
                  setShowUsers(true);
                }}>
                  <PeopleOutlineIcon />
                </IconButton>
              </TableCell>
              <TableCell >{row.basicId}</TableCell>
              <TableCell >
              {row.groupId}
              </TableCell>
              <TableCell >{row.pictureUrl ? <Avatar src={row.pictureUrl} /> : ""}</TableCell>
              <TableCell >{row.groupName}</TableCell>
              <TableCell align="right">{getDateText2(row.recordDate)}</TableCell>
              <TableCell ><Checkbox checked={row.registered} /></TableCell>
              <TableCell >{row.remark} {row.registered}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      {
        showEdit ? (
              <>
                <LineGroupEdit callbackResult={callbackResult} lineGroup={lineGroupEdit} customerList={customers}/>
              </>
            ) : ""
          }
      {
        showUsers ? (<>
          <LineAccountUser callbackResult={callbackResult} lineAccount={lineGroupEdit.lineAccount}/>
        </>) : ""
      }
      </LocalizationProvider>
    </>
  );
}
