import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import UpdateIcon from '@mui/icons-material/Update';
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AlertPopup from "./AlertPopup";
import useAlert from "../context/useAlert";
import moment from "moment";
import NodeNew from "./NodeNew";
import NodeEdit from "./NodeEdit";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomerEdit() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [showNewNode, setShowNewNode] = React.useState(false);
  const [showEditNode, setShowEditNode] = React.useState(false);
  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [createDate, setCreateDate] = React.useState("");
  const [saveDone, setSaveDone] = React.useState(false);
  const [editCustKey, setEditCustKey] = React.useState("-1");
  const [customer, setCustomer] = React.useState({});
  const [nodeEdit, setNodeEdit] = React.useState({});
  const [nodeAgentTasksEdit, setNodeAgentTasksEdit] = React.useState([]);
  
  const [nodes, setNodes] = React.useState([]);
  const [nodeAgentTasks, setNodeAgentTasks] = React.useState([]);
  

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const { setAlert } = useAlert();

  // get userId
  let customerKey = location.state.customerKey;

  React.useEffect(() => {
    console.log("CustomerEdit customerKey", customerKey);
    handleGetClick();
  }, []);

  const handleGetClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call customer/get", customerKey);

    try {
      const formData = new FormData();
      formData.append("key", customerKey);
      await api
        .get("customer/get?key=" + customerKey, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log("customer/get response", response.data);
          setTitle(response.data.title);
          setName(response.data.name);
          setCustomer(response.data);
          // moment.locale('en');
          setCreateDate(
            moment(response.data.createDate).format("D MMM yyyy HH:MM")
          );
          setEditCustKey(customerKey);
        });
    } catch (error) {
      console.log("customer/get response status ", error.response.status);
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
      console.log("customer/get error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRunJob = async (param) => {
    setLoading(true);
    console.log("Call agentTask/execute", param);

    try {
      const formData = new FormData();
      formData.append("key", param);
      await api
        .get("agentTask/execute?key=" + param, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log("agentTask/execute response", response.data);
          
        });
    } catch (error) {
      console.log("agentTask/execute response status ", error.response.status);
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
      console.log("agentTask/execute error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRescheduleJob = async (param) => {
    setLoading(true);
    console.log("Call agentTask/reschedule", param);

    try {
      const formData = new FormData();
      formData.append("key", param);
      await api
        .get("agentTask/reschedule?key=" + param, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log("agentTask/reschedule response", response.data);
          
        });
    } catch (error) {
      console.log("agentTask/reschedule response status ", error.response.status);
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
      console.log("agentTask/execute error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call customer/update");
    setSaveDone(false);

    try {
      // const req = {};
      // req.body = {
      const req = {
        title: title,
        name: name,
        key: customerKey,
      };
      await api
        .post("customer/update", req, { withCredentials: true })
        .then((response) => {
          console.log("customer/update response", response.data);
          //setNewCustKey(response.data.customerKey);
          setSaveDone(true);
          setAlert("Update success.", "success");
        });
    } catch (error) {
      //console.log("customer/update error ", error);
      setAlert(
        "Update failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (param) => {
    setLoading(true);
    console.log("Call customer/delete");
    setSaveDone(false);

    try {
      const req = {
        key: customerKey,
      };
      await api
        .post("customer/delete", req, { withCredentials: true })
        .then((response) => {
          console.log("customer/delete response", response.data);

          setSaveDone(true);
          setAlert("Delete success.", "success");
        });
    } catch (error) {
      //console.log("customer/update error ", error);
      setAlert(
        "Delete failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callbackResult = (param, param2) => {
    console.log("callbackResult ", param, param2);

    if (param && param === "cancel") {
      setShowNewNode(false);
      setShowEditNode(false);
    } else if (param === "confirm") {
      setShowNewNode(false);
      setShowEditNode(false);
      handleSearchNodeClick();
    }
  };

  const handleSearchAgentByCustomerClick = async (param) => {
    // setLoading(true);
    console.log("Call agent/search");

    try {
      const req = { key: customerKey };
      await api
        .post("agentTask/searchByCustomer", req, { withCredentials: true })
        .then((response) => {
          //console.log("agent/search response", response.data);
          console.log("agent length ", response.data.length);
          
          setNodeAgentTasks(response.data);
        });
    } catch (error) {
      setAlert(
        "Search agent error code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
    }
  };

  const handleSearchNodeClick = async (param) => {
    // setLoading(true);
    console.log("Call node/search");

    try {
      const req = { key: customerKey };
      await api
        .post("node/search", req, { withCredentials: true })
        .then((response) => {
          console.log("node/search response", response.data);

          console.log("node length ", response.data.length);
          if (response.data.length > 0) {
            console.log("node active ", response.data[0].active);
          }
          setNodes(response.data);
        });
    } catch (error) {
      setAlert(
        "Search node error code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
    }
  };

  const handleGetNode = async (param) => {
    setLoading(true);
    console.log("Call node/get", param);
    setShowEditNode(false);
    setNodeEdit({});
    setNodeAgentTasksEdit([]);

    try {
      const formData = new FormData();
      formData.append("key", param);
      await api
        .get("node/get?key=" + param, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //console.log("node/get response", response.data);
          setNodeEdit(response.data[0]);
          setNodeAgentTasksEdit(response.data[1]);
          setShowEditNode(true);
        });
    } catch (error) {
      console.log("node/get response status ", error.response.status);
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
      console.log("customer/get error ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertPopup />
      <p>Edit Customer</p>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="ข้อมูลลูกค้า" {...a11yProps(0)} />
            <Tab
              label="Nodes"
              {...a11yProps(1)}
              onClick={() => {
                console.log("Tab Nodes clicked");
                handleSearchNodeClick();
              }}
            />
            <Tab label="Agents" {...a11yProps(2)} 
              onClick={() => {
                console.log("Tab Agents clicked");
                handleSearchAgentByCustomerClick();
              }}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormGroup>
            <TextField
              id="standard-basic"
              label="ชื่อ"
              variant="filled"
              required
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
            <TextField
              id="standard-basic2"
              label="ชื่อเต็ม"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="standard-basic3"
              disabled
              label="วันที่สร้าง"
              variant="filled"
              value={createDate}
            />
          </FormGroup>
          <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
            {saveDone ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    console.log("editCustKey ", editCustKey);
                    navigate("/customerView", {
                      state: { customerKey: editCustKey },
                    });
                  }}
                >
                  <CheckIcon /> เปิดดู
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    console.log("Goto customerNew");
                    navigate("/customerNew");
                  }}
                >
                  <AddIcon /> สร้างใหม่
                </Button>
              </>
            ) : (
              <>
                <LoadingButton
                  onClick={handleUpdateClick}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>บันทึก</span>
                </LoadingButton>
                <LoadingButton
                  onClick={handleClickOpen}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<DeleteIcon />}
                  variant="contained"
                >
                  <span>ลบ</span>
                </LoadingButton>
                <LoadingButton
                  onClick={() => {
                    navigate("/customer");
                  }}
                  startIcon={<UndoIcon />}
                  variant="outlined"
                >
                  <span>ยกเลิก</span>
                </LoadingButton>
              </>
            )}
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              onClick={() => {
                setShowNewNode(true);
              }}
              size="small"
            >
              <AddIcon /> เพิ่ม
            </Button>
          </Stack>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            {nodes.map((node) => (
              <Grid item xs={4}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          {node.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} justifyContent="flex-end">                        
                        <IconButton aria-label="edit" onClick={(e) => {                          
                          handleGetNode(node.nodeKey);
                        }}>
                          <EditIcon/>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <FormControlLabel
                      control={<Switch checked={node.active} />}
                      label="Active"
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {showNewNode ? (
            <>
              <NodeNew callbackResult={callbackResult} customer={customer} />
            </>
          ) : (
            ""
          )}
          {
            showEditNode ? (
              <>
                <NodeEdit callbackResult={callbackResult} customer={customer} node={nodeEdit} nodeAgentTaskList={nodeAgentTasksEdit}/>
              </>
            ) : ""
          }
        </TabPanel>
        <TabPanel value={value} index={2}>
          Agents

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            {nodeAgentTasks.map((nodeAgentTask) => (
              <Grid item xs={4}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          {nodeAgentTask.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} justifyContent="flex-end">                        
                        <IconButton aria-label="edit" onClick={(e) => {                          
                          handleRunJob(nodeAgentTask.agentTaskKey);
                        }}>
                          <PlayArrowIcon/>
                        </IconButton>                 
                        <IconButton aria-label="reschedule" onClick={(e) => {                          
                          handleRescheduleJob(nodeAgentTask.agentTaskKey);
                        }}>
                          <UpdateIcon/>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <FormControlLabel
                      control={<Switch checked={nodeAgentTask.active} />}
                      label="Active"
                    />
                    <Typography
                      variant="overline"
                      display="block"
                    >
                      {nodeAgentTask.agentTask.cron}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete customer {title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteClick();
              handleClose();
            }}
          >
            OK
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
