import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from '@mui/icons-material/Cancel';
import UndoIcon from '@mui/icons-material/Undo';
import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AlertPopup from "./AlertPopup";
import useAlert from "../context/useAlert";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomerView() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [createDate, setCreateDate] = React.useState("");
  const [saveDone, setSaveDone] = React.useState(false);
  const [editCustKey, setEditCustKey] = React.useState("-1");

  const location = useLocation();
  const { setAlert } = useAlert();

  // get userId
  let customerKey = location.state.customerKey;

  React.useEffect(() => {
    console.log("CustomerEdit customerKey", customerKey);
    handleGetClick();
  }, []);

  const handleGetClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call customer/get", customerKey);

    try {
      const formData = new FormData();
      formData.append("key", customerKey);
      await api
        .get("customer/get?key="+ customerKey, { withCredentials: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .then((response) => {
          console.log("customer/get response", response.data);
          setTitle(response.data.title);
          setName(response.data.name);
          // moment.locale('en');
          setCreateDate( moment(response.data.createDate).format('D MMM yyyy HH:MM'));
          setEditCustKey(customerKey);
        });
    } catch (error) {
      console.log("customer/get response status ", error.response.status);
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');
      console.log("customer/get error ", error);
      
    } finally {
      setLoading(false);      
    }
  };

  const handleUpdateClick = async (param) => {
    // function handleSaveClick() {
    setLoading(true);
    console.log("Call customer/update");
    setSaveDone(false);

    try {
      // const req = {};
      // req.body = {
      const req = {
        title: title,
        name: name,
        key: customerKey,
      };
      await api
        .post("customer/update", req, { withCredentials: true })
        .then((response) => {
          console.log("customer/update response", response.data);
          //setNewCustKey(response.data.customerKey);
          setSaveDone(true);
          setAlert('Update success.', 'success');
        });
    } catch (error) {
      //console.log("customer/update error ", error);
      setAlert('Update failed: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <><AlertPopup/>
      <p>View Customer</p>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="ข้อมูลลูกค้า" {...a11yProps(0)} />
            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormGroup>
            <TextField
              id="standard-basic"   
              label="ชื่อ"
              variant="filled"
              required
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
            <TextField
              id="standard-basic2" disabled
              label="ชื่อเต็ม"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="standard-basic3" disabled
              label="วันที่สร้าง"
              variant="filled"
              value={createDate}              
            />
          </FormGroup>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel> */}
      </Box>
      {/* <Button variant="contained" >
        <SaveIcon /> บันทึก
      </Button> */}
          <Stack direction="row" spacing={2}>
      <Button
            variant="outlined"
            onClick={() => {
              console.log("customerKey", customerKey);
              navigate("/customerEdit", {
                state: { customerKey: customerKey },
              });
            }}
          >
            <EditIcon /> แก้ไข
          </Button>
        <LoadingButton onClick={() => {navigate("/customer")}}               
          startIcon={<UndoIcon />}
          variant="outlined"
        >
          <span>ยกเลิก</span>
        </LoadingButton></Stack>
      
    </>
  );
}
