import { Avatar, Box, Button, Checkbox, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import api from "../services/api";
import useAlert from "../context/useAlert";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";
import LineWebhookView from "./LineWebhookView";
import dayjs from "dayjs";
import LineGroupEdit from "./LineGroupEdit";
import LineAccountEdit from "./LineAccountEdit";
import LineAccountUser from "./LineAccountUser";


export default function LineAccount() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showUsers, setShowUsers] = React.useState(false);
  const [lineAccountEdit, setLineAccountEdit] = React.useState();

  React.useEffect(() => {
    console.log("useEffect LineAccount");
  }, []);

  const { setAlert } = useAlert();

  const callbackResult = (param, param2) => {
    console.log("callbackResult ", param, param2);

    if (param && param === "cancel") {
     
    } else if (param === "confirm") {
      setLineAccountEdit(null);
      setShowEdit(false);
      handleSearchClick();
    }
  };

  const handleSearchClick = async (param) => {
    
    setLoading(true);
    console.log("Call lineAccount/search");
    console.log("txt", txt);
    setLineAccountEdit(null);
    setShowEdit(false);
    
    try {      
      const req = {
        txt: txt
      };
      await api
        .post("lineAccount/search", req, { withCredentials: true })
        .then((response) => {          
          setRows(response.data);          
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');      
    } finally {
      setLoading(false);
    }
  };

  return (
    <><LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant="subtitle1" >Line Account</Typography>
      
      <Stack spacing={2} direction="row">
        <TextField
          id="filled-basic"
          label="Basic ID"
          variant="outlined"
          size="small" value={txt} 
              onChange={(event) => {
                setTxt(event.target.value);
              }}
        />
          <LoadingButton
            onClick={handleSearchClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            variant="contained"
          >
            <span>ค้นหา</span>
          </LoadingButton>
      </Stack>


      <TableContainer component={Paper} style={{marginTop:10}}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width={200}></TableCell>
            <TableCell width={150}>Basic Id</TableCell>
            <TableCell width={80}></TableCell>
            <TableCell width={300}>Display Name</TableCell>
            <TableCell width={100}>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.basicId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <IconButton aria-label="Edit" onClick={(e) => { 
                  setLineAccountEdit(row);
                  setShowEdit(true);
                  setShowUsers(false);
                  // setLineGroupEdit({});
                }}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="View Users" onClick={(e) => { 
                  setLineAccountEdit(row);
                  setShowEdit(false);
                  setShowUsers(true);
                }}>
                  <PeopleOutlineIcon />
                </IconButton>
              </TableCell>
              <TableCell >{row.basicId}</TableCell>
              <TableCell >{row.publicUrl ? <Avatar src={row.publicUrl} /> : ""}</TableCell>
              <TableCell >{row.displayName}</TableCell>
              <TableCell ><Checkbox checked={row.active} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      {
        showEdit ? (
              <>
              <LineAccountEdit callbackResult={callbackResult} lineAccount={lineAccountEdit} />
              </>
            ) : ""
          }
      {
        showUsers ? (<>
          <LineAccountUser callbackResult={callbackResult} lineAccount={lineAccountEdit}/>
        </>) : ""
      }
      </LocalizationProvider>
    </>
  );
}
