import React from "react";
import PropTypes from "prop-types";
import api from "../services/api";
import { Button, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import UndoIcon from "@mui/icons-material/Undo";
import useAlert from "../context/useAlert";

export default function NodeNew({ callbackResult, customer }) {
  const [name, setName] = React.useState("");
  const [active, setActive] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const { setAlert } = useAlert();

  const handleSaveClick = async (param) => {
    
    setLoading(true);
    console.log("Call node/create");
    
    // setSaveDone(false);
    // setNewCustKey("-1");

    // callbackResult("confirm", "ok");

    try {
      // const req = {};
      // req.body = {
      const req = {
        active: active,
        name: name,
        key: customer.customerKey
      };
      await api
        .post("node/create", req, { withCredentials: true })
        .then((response) => {
          console.log("node/create response", response.data);
          callbackResult("confirm", "ok");

        setAlert(
            "Create node success.", "success"
          );
        //   setNewCustKey(response.data.customerKey);
        //   setSaveDone(true);
        });
    } catch (error) {
        setAlert(
            "Create failed: " +
              error.response.status +
              ": [" +
              error.response.data.error +
              "] " +
              error.response.data.message,
            "error"
          );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }}>
        <Typography variant="subtitle1">Create a new node</Typography>
        <p>Customer: {customer.name}</p>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth
              id="nodeNewName"
              label="ชื่อ Node"
              variant="filled"
              required
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={active} onChange={(event) => {setActive(event.target.checked)}} />} label="Active" />
          </Grid>
          
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
              <LoadingButton
                onClick={handleSaveClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>บันทึก</span>
              </LoadingButton>
              <LoadingButton
                onClick={() => {
                  callbackResult("cancel", "ca");
                }}
                startIcon={<UndoIcon />}
                variant="outlined"
              >
                <span>ยกเลิก</span>
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
