import React from "react";
import PropTypes from "prop-types";
import api from "../services/api";
import { Button, ButtonGroup, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import UndoIcon from "@mui/icons-material/Undo";
import useAlert from "../context/useAlert";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import DeleteIcon from '@mui/icons-material/Delete';

export default function NodeEdit({ callbackResult, customer, node, nodeAgentTaskList }) {
  
  const [name, setName] = React.useState("");
  const [active, setActive] = React.useState(true);
  const [nodeAgentTasks, setNodeAgentTasks] = React.useState([]);
  //const [rows, setRows] = React.useState([]);
  const [agentTasks, setAgentTasks] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  

  const { setAlert } = useAlert();

  React.useEffect(() => {
    console.log("useEffect NodeEdit node", node);
    setName(node.name);
    setActive(node.active);
    setNodeAgentTasks(nodeAgentTaskList);
    handleGetAllAgentTask();
  }, []);



  const handleGetAllAgentTask = async (param) => {
    setLoading(true);
    console.log("Call agentTask/getall", param);
    
    try {
      const formData = new FormData();
      formData.append("key", param);
      await api
        .get("agentTask/getall?key=" + param, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //console.log("node/get response", response.data);
          setAgentTasks(response.data);
          
        });
    } catch (error) {
      console.log("agentTask/getall response status ", error.response.status);
      setAlert(
        "Code: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
      console.log("agentTask/getall error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveClick = async (param) => {
    
    setLoading(true);
    console.log("Call node/update");
    
    // setSaveDone(false);
    // setNewCustKey("-1");

    // callbackResult("confirm", "ok");

    try {
      // const req = {};
      // req.body = {
      const req = {
        active: active,
        name: name,
        key: node.nodeKey,
        nodeAgentTasks: nodeAgentTasks
      };
      await api
        .post("node/update", req, { withCredentials: true })
        .then((response) => {
          console.log("node/update response", response.data);
          callbackResult("confirm", "ok");

        setAlert(
            "Update node success.", "success"
          );
        //   setNewCustKey(response.data.customerKey);
        //   setSaveDone(true);
        });
    } catch (error) {
        setAlert(
            "Create failed: " +
              error.response.status +
              ": [" +
              error.response.data.error +
              "] " +
              error.response.data.message,
            "error"
          );
    } finally {
      setLoading(false);
    }
  };


  // const rows = [
  //   createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  //   createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  //   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  //   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  //   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  // ];

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={5}>
        <Typography variant="subtitle1">Edit node</Typography>
        <p>Node: {node.nodeKey}</p>

        <Grid container spacing={2} justifyContent="flex-start" alignItems="baseline">
          <Grid item xs={12}>
            <TextField fullWidth
              id="nodeNewName"
              label="ชื่อ Node"
              variant="filled"
              required
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={active} onChange={(event) => {setActive(event.target.checked)}} />} label="Active" />
          </Grid>
          <Grid item xs={12}>
          <Grid container spacing={2} sx={{backgroundColor:"#f7f7f7"}} justifyContent="flex-start" alignItems="baseline">
          <><p>Selected:</p>{nodeAgentTasks.map((item, key) => (<><Grid item xs="auto">
              <ButtonGroup variant="contained" aria-label="split button">
                <Button key={"k" + key} value={item.natKey} onClick={(e) => {
                  console.log("Button Delete natKey", e.target.value);
                }}>{item.name}</Button>
                <Button size="small" value={key} onClick={(e2) => {
                  console.log("Icon Delete natKey index", e2.target.value);

                  var array = [...nodeAgentTasks]; // make a separate copy of the array
                  // var index = array.indexOf(e2.target.value)
                  // if (index !== -1) {
                  //   array.splice(index, 1);
                  //   //this.setState({people: array});
                  //   setNodeAgentTasks(array);
                  // }

                  array.splice(e2.target.value, 1);
                    //this.setState({people: array});
                    setNodeAgentTasks(array);
                  
                }}>X</Button>
              </ButtonGroup></Grid>
              </>
        ))
        }</>
          </Grid></Grid>
          <Grid item xs={12}>
            <Toolbar><p>All:</p>
            </Toolbar>
            <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell />
                  <TableCell align="right">ID</TableCell>
                  <TableCell >Name</TableCell>
                  <TableCell align="right">File Path</TableCell>
                  <TableCell align="right">Cron</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
        <TableBody>
          {agentTasks.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Button value={row.agentTaskKey} onClick={(e) => {

                  console.log("Clicked add agentTaskKey", e.target.value);

                  let found = false;
                  nodeAgentTasks.map((r) => {
                    console.log("Check existing", r.agentTaskKey);
                    
                    if (''+r.agentTaskKey === ''+e.target.value) {
                      found = true;
                      console.log("found existing", r.agentTaskKey);
                    }
                  });

                  if (!found) {
                    console.log("Not found, Adding agentTask", e.target.value);
                    
                    agentTasks.map((r2) => {
                      console.log(" r2", r2.agentTaskKey);
                      if (''+r2.agentTaskKey === ''+e.target.value) {
                        console.log("Added existing1", r2.agentTaskKey);
                        const finalArray = [...nodeAgentTasks, r2];
                        setNodeAgentTasks( finalArray );
                        console.log("Added existing2", r2.agentTaskKey);           
                      }
                    });
                    console.log("nodeAgentTasks size", nodeAgentTasks.length);
                  }

                }}>add</Button>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {row.agentTaskKey}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.agentTaskKey}</TableCell>
              <TableCell align="right">{row.cron}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
            </Table>

            </TableContainer>
          </Grid>
          
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
              <LoadingButton
                onClick={handleSaveClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>บันทึก</span>
              </LoadingButton>
              <LoadingButton
                onClick={() => {
                  callbackResult("cancel", "ca");
                }}
                startIcon={<UndoIcon />}
                variant="outlined"
              >
                <span>ยกเลิก</span>
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
