import React from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import useAlert from "../context/useAlert";
import api from "../services/api";

export default function LineGroupEdit({
  callbackResult,
  lineGroup,
  customerList,
}) {
  //const [lineWebhookEdit, setLineWebhookEdit] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [remark, setRemark] = React.useState("");
  const [active, setActive] = React.useState(true);
  const [basicId, setBasicId] = React.useState("");
  const [customers, setCustomers] = React.useState([]);
  const [editCustomers, setEditCustomers] = React.useState([]);
  const { setAlert } = useAlert();

  React.useEffect(() => {
    console.log("useEffect LineGroupEdit lineGroup", lineGroup);

    setRemark(lineGroup.remark);
    setActive(lineGroup.registered);
    setBasicId(lineGroup.basicId);
    setEditCustomers(lineGroup.customerLineGroups);
    // setLineWebhookEdit(lineWebhook);
  }, []);

  const handleSaveClick = async (param) => {
    setLoading(true);
    console.log("Call lineGroup/update");

    // setSaveDone(false);
    // setNewCustKey("-1");

    // callbackResult("confirm", "ok");

    try {
      // const req = {};
      // req.body = {
      const req = {
        key: lineGroup.groupId,
        basicId: basicId,
        active: active,
        remark: remark,
        customerLineGroups: editCustomers
      };
      await api
        .post("lineGroup/update", req, { withCredentials: true })
        .then((response) => {
          console.log("lineGroup/update response", response.data);
          callbackResult("confirm", "ok");

          setAlert("Update lineGroup success.", "success");
        });
    } catch (error) {
      setAlert(
        "Create failed: " +
          error.response.status +
          ": [" +
          error.response.data.error +
          "] " +
          error.response.data.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper sx={{ padding: 2, marginTop: 3 }} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Edit Line Group ID: {lineGroup.groupId} {lineGroup.pictureUrl ? <Avatar src={lineGroup.pictureUrl} /> : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Group Name"
              value={lineGroup.groupName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Remark"
              value={remark}
              fullWidth
              onChange={(event) => {
                setRemark(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(event) => {
                    setActive(event.target.checked);
                  }}
                />
              }
              label="Registered"
            />
          </Grid>
          <Grid item xs={12}>
            <p>Selected:</p>
            <Grid container spacing={2} sx={{backgroundColor:"#f7f7f7"}} justifyContent="flex-start" alignItems="baseline">
              {editCustomers.map((item, key) => (<><Grid item xs="auto">
                  <ButtonGroup variant="contained" aria-label="split button">
                    <Button key={"k" + key} value={item.clgKey} onClick={(e) => {
                      console.log("Button Delete clgKey", e.target.value);
                    }}>{item.name}</Button>
                    <Button size="small" value={key} onClick={(e2) => {
                      console.log("Icon Delete clgKey index", e2.target.value);
                      var array = [...editCustomers];
                      array.splice(e2.target.value, 1);
                        setEditCustomers(array);                    
                    }}>X</Button>
                  </ButtonGroup></Grid>
                  </>
              ))
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <p>All:</p>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell/>
                    <TableCell align="right">ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerList.map((row) => (
                    <TableRow
                      key={row.customerKey}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Button
                          value={row.customerKey}
                          onClick={(e) => {
                            console.log(
                              "Clicked add customer",
                              e.target.value
                            );

                            let found = false;
                            editCustomers.map((customerLineGroup) => {
                              console.log("Check existing", customerLineGroup.customerKey);

                              if ("" + customerLineGroup.customerKey === "" + e.target.value) {
                                found = true;
                                console.log("found existing", customerLineGroup.customerKey);
                              }
                            });

                            if (!found) {
                              console.log(
                                "Not found, Adding customer",
                                e.target.value
                              );

                              customerList.map((r2) => {
                                console.log(" r2", r2.customerKey);
                                if (
                                  "" + r2.customerKey ===
                                  "" + e.target.value
                                ) {
                                  console.log(
                                    "Added existing1",
                                    r2.customerKey
                                  );
                                  const finalArray = [...editCustomers, r2];
                                  setEditCustomers(finalArray);
                                  console.log(
                                    "Added existing2",
                                    r2.customerKey
                                  );
                                }
                              });
                              console.log(
                                "editCustomers size",
                                editCustomers.length
                              );
                            }
                          }}
                        >
                          add
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {row.customerKey}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">{row.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
              <LoadingButton
                onClick={handleSaveClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>บันทึก</span>
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
