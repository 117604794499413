import { Box, Button, IconButton, Stack, Switch, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import api from "../services/api";
import useAlert from "../context/useAlert";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

function getDateText(params) {
  return moment(new Date(params.row.createDate)).format('D MMM yyyy HH:MM');
}

export default function AgentTask() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [rows, setRows] = React.useState([]);


  function getKey(params) {
    return (<><IconButton aria-label="Edit" onClick={() => {    
      navigate("/agentTaskEdit", {
        state: { agentTaskKey: params.row.agentTaskKey },
      });
    }}>
    <EditIcon />
  </IconButton></>);
  }

  const columns = [
    { headerName: "", width: 40, renderCell: getKey },
    { field: "agentTaskKey", headerName: "Key", width: 80 },
    { field: "name", headerName: "Name", width: 300 },
    { field: "active", headerName: "Active", width: 100, renderCell: (params: GridRenderEditCellParams) => (
      <Switch checked={params.row.active} size="small" />
    )  },
    { field: "cron", headerName: "Cron", width: 120 },
    { field: "createDate", headerName: "Create Date", width: 150, valueGetter: getDateText },
    
  ];

  const { setAlert } = useAlert();

  const handleSearchClick = async (param) => {
    
    setLoading(true);
    console.log("Call agentTask/search");

    try {
      
      const req = {
        txt: txt
      };
      await api
        .post("agentTask/search", req, { withCredentials: true })
        .then((response) => {
          setRows(response.data);          
        });
    } catch (error) {
      setAlert('Code: ' + error.response.status + ': [' + error.response.data.error + '] ' + error.response.data.message, 'error');
      // console.log("customer/search error ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="subtitle1" >Agent Tasks</Typography>
      <Stack spacing={2} direction="row">
        <TextField
          id="filled-basic"
          label="ชื่อ"
          variant="outlined"
          size="small" value={txt} 
              onChange={(event) => {
                setTxt(event.target.value);
              }}
        />
          <LoadingButton
            onClick={handleSearchClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SearchIcon />}
            variant="contained"
          >
            <span>ค้นหา</span>
          </LoadingButton>
        <Button variant="outlined" onClick={() => {navigate("/agentTaskNew")}}>
          <AddIcon /> เพิ่ม 
        </Button>
      </Stack>

      <Box
        sx={{
          width: "100%",
          height: 500,
          marginTop: 5,
          
        }}
      >
        <DataGrid rows={rows} columns={columns} getRowId={(row) => row.agentTaskKey} />
      </Box>
    </>
  );
}
