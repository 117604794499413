import React from "react";
import PropTypes from "prop-types";
import api from "../services/api";

import "../css/Login.css";
import { TextField } from "@mui/material";

async function loginUser(credentials) {
  return "OK";
}

async function checkLoginToken() {
  
  try {
    console.log("Call date/now");

    const res = await api.get("date/now", {
      withCredentials: true,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      console.log("date/now response", response.data);
    });

  } catch (error) {
    console.log("error ", error.response.status);

    var txt = "Error " + error.response.status;
    console.log("Error txt", txt);
    if (error.response.status === 401) {
      //showError(txt);
    }
  }
}

async function logout() {
  
  try {
    console.log("Call logout");

    const res = await api.post("logout", {}, {
      withCredentials: true,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      console.log("logout response", response.data);
      localStorage.removeItem('token');
    });

  } catch (error) {
    console.log("error ", error.response.status);

    var txt = "Error " + error.response.status;
    console.log("Error txt", txt);
    if (error.response.status === 401) {
      //showError(txt);
    }
  }
}

export default function Login({ setToken }) {
  
  const [uid, setUid] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // const token = await loginUser({

    // });
    // console.log('token: ' + token);
    // setToken(token);

    try {
      const formData = new FormData();
      if (uid === 'x') {
        formData.append("username", "tukz");
        formData.append("password", "20200509#");
      } else {

      }

      console.log("Call perform_login");

      const res = await api.post("perform_login", formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((response) => {
        // console.log("res", res);
        // console.log("res.data", res.data);
        // console.log("res.headers", res.headers);

        console.log("perform_login response", response.data);
        setToken(response.data);
        localStorage.setItem('token', response.data);
        //return response.data;
      });
      
      // api.post("perform_login", req, { withCredentials: true }).then((response) => {
      //   console.log("perform_login response", response.data);        
      // });

    } catch (error) {
      console.log("error ", error.response.status);

      var txt = "Error " + error.response.status;
      console.log("Error txt", txt);
      if (error.response.status === 401) {
        //showError(txt);
      }
    }
  };

  const handleLogout = async (e) => {

    try {
      console.log("Call handleLogout");
  
      const res = await api.post("logout", {}, {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((response) => {
        console.log("handleLogout response", response.data);
        setToken(null);
        localStorage.removeItem('token');
      });
  
    } catch (error) {
      console.log("error ", error.response.status);
  
      var txt = "Error " + error.response.status;
      console.log("Error txt", txt);
      if (error.response.status === 401) {
        //showError(txt);
      }
    }
  };

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>

            <TextField
              label="User"
              variant="filled"
              value={uid}
              onChange={(event) => {
                setUid(event.target.value);
              }}
            />
        </label>
        <label>
          <p>Password</p>
          <input type="password" />
        </label>
        <div>
          <button type="submit">Submit</button>
          <button
            type="button"
            onClick={() => {
              checkLoginToken();
            }}
          >
            Check Login
          </button>
          <button
            type="button"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </button>
          <button
            type="button"
            onClick={() => {
              handleLogout();
            }}
          >
            Call handleLogout
          </button>
        </div>
      </form>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
